import React, { useRef, useState, useEffect } from "react";
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function About() {
    useEffect(() => {
        AOS.init();
    }, [])

    let titleHistory = 'img/title_about.png'
    let titleKids = 'img/title_about_kids.png'
    let titleAdult = 'img/title_about_adult.png'

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);

    const togglePlay = () => {
        const video = videoRef.current;
        const bgMobile = document.getElementById("bgMobile");
        bgMobile.style.display = "none";

        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
        setIsPlaying(!isPlaying);
    };

    const toggleMute = () => {
        const video = videoRef.current;
        video.muted = !video.muted;
        setIsMuted(!isMuted);
    };

    function sendKids(e) {
        e.preventDefault();
      
        const textSend = `Olá, vim pelo site e gostaria de agendar um horário para meu filho.`;
        const whatsappLink = `https://wa.me/5551998444717?text=${encodeURIComponent(textSend)}`;
        
        window.open(whatsappLink, '_blank');
      }

      function sendAdout(e) {
        e.preventDefault();
      
        const textSend = `Olá, vim pelo site um e gostaria de agendar um trato no visual.`;
        const whatsappLink = `https://wa.me/5551998444717?text=${encodeURIComponent(textSend)}`;
        
        window.open(whatsappLink, '_blank');
      }


    return(
        <section className='about box' id="about">
            <div className="history box">
                <div>
                    <img src={titleHistory} alt="" />
                    <div className="text box">
                        <p className="box" data-aos="fade-right" data-aos-duration="1500" >Desde 2014 a Deodoro foi pensada e elaborada para fazer a diferença no dia a dia, no estilo e na autoestima de cada homem!</p>
                        <p className="box" data-aos="fade-right" data-aos-duration="2000" >São mais de 9 anos de história cuidando do seu visual, conectando experiências e bons momentos, tudo o que o homem moderno gosta; networking, tempo para relaxar em um ambiente agradável, café expresso, aquela gelada e você ainda sai com o cabelo e barba bem alinhados.</p>
                        <p className="box" data-aos="fade-right" data-aos-duration="2500" >A Deodoro acredita que as histórias também são feitas de momentos entre você e seu filho, por isso em Outubro de 2020 inauguramos a Deodoro Kids – a primeira barbearia especializada em atendimento infantil a partir dos 3 meses de idade. Assim você e o seu pequeno grande homem podem desfrutar de uma experiência única.</p>
                        
                       <button onClick={sendAdout}>Viver a experiência Deodoro</button>
                    </div>
                </div>
                <div>
                    <figure>
                        <img className="box" src="img/about_home_1.jpg" alt="" data-aos="fade-down-left" data-aos-duration="1500" />
                    </figure>
                    <figure>
                        <img className='show' src="img/about_home_2.jpg" alt="" data-aos="fade-down-left" data-aos-duration="2000" />
                    </figure>
                </div>
            </div>

            <div className="kids">
                <div data-aos="fade-up-right" data-aos-duration="2500" >
                    <img onClick={togglePlay} className='show' src="img/about_kids.png" alt="" />
                    <div>
                        <video ref={videoRef} src="./video/kids.mp4" loop></video>
                        <img id="bgMobile" src="./img/about_kids.jpg" />
                        <div className="box">
                        {!isPlaying && (
                        <i class="fa-solid fa-circle-play"></i>
                        )}
                        </div>
                    </div>
                </div>

                <div>
                    <img src={titleKids} alt="Deodoro Kids" />
                    <div className="text">
                        <p className="box" data-aos="fade-left" data-aos-duration="1500" >A Deodoro Kids entende que  tanto o 1º corte como todas as outras necessárias idas ao barbeiro, não precisam ser traumáticas, nem para os pais e principalmente para as crianças.</p>
                        <p className="box" data-aos="fade-left" data-aos-duration="2000" >A experiência do seu pequeno ao cortar o cabelinho, precisa ser positiva e tranquila, fazendo com que ele se sinta confiante, se divirta e ainda saia com o visual renovado. Com cuidado e paciência o Tio Rudi, nosso profissional especializado em bebês e crianças com TEA, se divertem juntos com os diversos jogos e brinquedos em um espaço interativo e divertido.
                        <br/>
                        <br/>
                        Seu pequeno grande homem merece ter uma experiência Deodoro Kids.</p>
                        
                        <button onClick={sendKids}>Viver a experiência Deodoro Kids</button>
                    </div>
                </div>
                
            </div>

            <div className="history adout">
                <div>
                    <img src={titleAdult} alt="" />
                    <div className="text">
                        <p data-aos="fade-right" data-aos-duration="1500" >A Deodoro é mais que apenas uma barbearia. É um ponto de encontro para o homem moderno. Aqui toda sexta-feira já é de praxe você encontrar seus amigos e parceiros para tomar uma gelada, bater aquele papo e descontrair em um ambiente aconchegante e amigável.</p>
                        <p data-aos="fade-right" data-aos-duration="2000" >Se você curte ambientes assim e busca esse tipo de experiência que vai além de um corte e uma barba bem feita, seja muito bem vindo ao nosso clube!</p>
                    </div>
                </div>
                <div>
                    <figure>
                        <img src="img/about_adout_1.jpg" alt="" data-aos="fade-down-left" data-aos-duration="1500" />
                    </figure>
                    <figure>
                        <img className='show' src="img/about_adout_2.jpg" alt="" data-aos="fade-down-left" data-aos-duration="2000" />
                    </figure>
                </div>
            </div>
        </section>
    )
}

export default About;