import React, { useState, useEffect } from 'react';
import './BarberShop.css';
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

function BarberShop() {
    useEffect(() => {
        AOS.init();
    }, [])

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const maxImagesToShow = 6;

    // Array de produtos com informações das imagens e descrições
    const products = [
        {
        id: 1,
        imageSrc: '../img/shop_1.jpg',
        title: 'BONÉ ADULTO',
        descriptionOne: `
        Boné masculino da marca Deodoro, para quem busca estilo e proteção.
        `,
        descriptionTwo: `
        Garanta já o seu!
        `,
        priceOff: 'R$129,99',
        price: 'R$75'
        },
        {
        id: 2,
        imageSrc: '../img/shop_2.jpg',
        title: 'HOME SPRAY',
        infoContent: 'Tem como função desodorizar tecidos e ambientes, eliminar ácaros, micro-organismos, fungos e bactérias.',
        descriptionOne: `
        Inspirado na fragrância de Animale, sua composição equilibra notas
cítricas e amadeiradas, tornando-o um perfume sutil e ao mesmo tempo marcante,
adequado para o uso diário, sem enjoar de sua fragrância.

        `,
        descriptionTwo: `
        Ideal para o homem moderno,
que aprecia uma fragrância agradável em seu ambiente de trabalho, no carro ou onde
preferir.
        `,
        infoContent: `Ao usar nosso home spray, você se conecta com uma comunidade que valoriza a arte da barbearia e a busca pela excelência.`,
        priceOff: 'R$45,99',
        price: 'R$50'
        },
        {
        id: 3,
        imageSrc: '../img/shop_7.jpg',
        title: 'BONÉ KIDS',
        descriptionOne: `
        Boné infantil da marca Deodoro Kids. Estilo e proteção para crianças acima de 3 anos. 
        `,
        descriptionTwo: `
        Garanta já o boné do seu pequeno!
        `,
        priceOff: 'R$89,99',
        price: 'R$50'
        },
        {
        id: 4,
        imageSrc: '../img/shop_4.jpg',
        title: 'SHAMPOO FORCE',
        category: 'Shampoo',
        infoContent: `Ao usar nosso shampoo, você se conecta com uma comunidade que valoriza a arte da barbearia e a busca pela excelência.  `,
        descriptionOne: `
        Fortificante e anti oleosidade. Sua fórmula é enriquecida por ativos
        como extrato de Capsicum, Jaborandi e Mentol.
        `,
        descriptionTwo: `
        Proporciona brilho intenso e redução na
        oleosidade do couro cabeludo.
        `,
        priceOff: 'R$109,90',
        price: 'R$45'
        },
        {
        id: 5,
        imageSrc: '../img/shop_5.jpg',
        title: 'BALSAMO & MODELADOR',
        category: 'Pomada',
        infoContent: `
        
        `,
        descriptionOne: `
        Desenvolvido para proporcionar hidratação e nutrição da barba. Atua como
um selante, prolongando a ação dos nutrientes e alinhando os fios. Além disso facilita o
modelar da barba e dispõe de um leve perfume.
        `,
        descriptionTwo: `
        Ao usar nosso balsamo modelador, você se conecta com uma comunidade que valoriza a arte da barbearia e a busca pela excelência.  
        `,
        priceOff: 'R$78,99',
        price: 'R$60'
        },
        {
        id: 6,
        imageSrc: '../img/shop_6.jpg',
        title: 'CERA EM PÓ',
        infoContent: 'Ao usar nossa cera em pó, você se conecta com uma comunidade que valoriza a arte da barbearia e a busca pela excelência.',
        descriptionOne: `
        Proporciona volume ao penteado, efeito seco e alta fixação. Indicado para cabelos super finos.
        `,
        descriptionTwo: `
        
        `,
        priceOff: 'R$79,99',
        price: 'R$47'
        },
        {
        id: 7,
        imageSrc: '../img/shop_8.jpg',
        title: 'POMADA MATTE',
        category:  'Pomada',
        infoContent: 'Ao usar nossa pomada matte, você se conecta com uma comunidade que valoriza a arte da barbearia e a busca pela excelência.',
        descriptionOne: `
        Fixação forte e efeito natural. Sua fórmula é enriquecida por ativos que não agridem os fios, podendo-se utilizar diariamente.
        `,
        descriptionTwo: `
        NÍVEL DE FIXAÇÃO: 5
        NÍVEL DE BRILHO: 0
        `,
        priceOff: 'R$79,99',
        price: 'R$60'
        },
        {
        id: 8,
        imageSrc: '../img/shop_3.jpg',
        title: 'POMADA BRILHANTINA',
        category: 'Pomada',
        infoContent: 'Ao usar nossa pomada fixadora, você se conecta com uma comunidade que valoriza a arte da barbearia e a busca pela excelência.  ',
        descriptionOne: `
        Proporciona fixação forte e efeito brilho molhado de longa duração. Auxilia na diminuição do frizz.
        
        `,
        descriptionTwo: `
        NÍVEL DE FIXAÇÃO: 5
        NÍVEL DE BRILHO: 7
        `,
        priceOff: 'R$78,99',
        price: 'R$60'
        }
        // Adicione outros produtos aqui
    ];

    // Função para exibir o modal do produto
    const openModal = (product) => setSelectedProduct(product);

    // Função para fechar o modal
    const closeModal = () => setSelectedProduct(null);
    const shopClose = () => setSelectedProduct(null);

    // Função para filtrar imagens por categoria
    const filterImagesByCategory = (category) => {
        setSelectedCategory(category);
        
    };

    // Filtra as imagens de acordo com a categoria selecionada ou exibe todas se a categoria for "Todos"
    const filteredImages = selectedCategory
        ? products.filter((product) => product.category === selectedCategory)
        : products;

    // Limita a quantidade máxima de imagens exibidas
    const imagesToShow = filteredImages.slice(0, maxImagesToShow);

    const [infoShow, setInfoShow] = useState(false)

    

    return(
        <section className="BarberShop" id="mencare">
            <img src="../img/title_shop.png" alt="" />

            <ul className="menuProducts">
                <li onClick={() => filterImagesByCategory(null)}>Todos</li>
                <li onClick={() => filterImagesByCategory('Shampoo')}>Shampoo</li>
                <li onClick={() => filterImagesByCategory('Pomada')}>Pomada</li>
            </ul>

            <div className="Products" >
                {imagesToShow.map((product) => (
                    <img
                    data-aos="flip-right"
                    data-aos-duration="1500"
                    key={product.id}
                    src={product.imageSrc}
                    alt=""
                    onClick={() => openModal(product)}
                    />
                ))}
            </div>

            {/* Modal */}
            {selectedProduct && (
                <div className="modal" onClick={closeModal}>
                    <div data-aos="fade-up" className="modalContent" onClick={(e) => e.stopPropagation()}>
                        <span>
                            
                            <img src={selectedProduct.imageSrc} alt="" />
                            <div id="fog"></div>
                            
                            
                        </span>
                        <div>
                            <h2>{selectedProduct.title}</h2>
                            <div className="scroll">
                                <div className="text">
                                    <p>{selectedProduct.descriptionOne}</p>
                                    <br/>
                                    <p>{selectedProduct.descriptionTwo}</p>
                                </div>
                                <img src="../img/scroll.gif"></img>
                            </div>

                            <div className="price">
                                <h3>{selectedProduct.priceOff}</h3>
                                <h3>{selectedProduct.price}</h3>
                            </div>
                            <button>comprar</button>
                        </div>
                        <h2 onClick={shopClose} id='shopClose'>X</h2>
                        
                    </div>
                </div>
            )}
        </section>
    )
}

export default BarberShop;