import Menu from "./Menu";
import Hero from "./Hero";

function Header() {
  return (
    <header>
        <Menu/>
        <Hero/>
    </header>
  )
}

export default Header;