import React, { Component } from "react";
import SlideDepoimentOne from "./SlideDepoimentOne";
import './Depoiments.css'

export default class Depoiments extends Component {
    render() {
    var titleDepoiments = '../img/title_depoiments.png'
    
      return (
        <section className="Depoiments">
          <img src={titleDepoiments} alt="" />
          <SlideDepoimentOne/>
        </section>
      );
    }
  }