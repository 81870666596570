import React, { useState, useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Hero() {
    useEffect(() => {
        AOS.init();
    }, []);

    const [activeIndex, setActiveIndex] = useState(0);

    const backgrounds = [
        "url('../img/hero_1.jpg')",
        "url('../img/hero_2.jpg')",
        "url('../img/hero_4.jpg')",
        "url('../img/hero_3.jpg')",
    ];

    const titles = ["titleBarber", "titleMencare"];

    var bgHero = document.getElementById("bgHero");

    const functions = [
        () => {
            showTitle("titleMencare");
            if(window.innerWidth < 600) {
                var heroMobile = document.getElementById("bgHero");

                heroMobile.style.backgroundImage = "url('../img/hero_2.jpg')";
            }
        },
        () => {
            showTitle("titleBarber");
        }
    ];

    const showTitle = (titleId) => {
        titles.forEach(id => {
            const title = document.getElementById(id);
            if (id === titleId) {
                title.style.display = "block";
                title.style.opacity = "1";
            } else {
                title.style.display = "none";
                title.style.opacity = "0";
            }
        });

        AOS.refresh(); // Atualize o AOS para aplicar animações aos elementos visíveis
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((activeIndex + 1) % backgrounds.length);

            // Executa a função correspondente

            AOS.refresh(); // Atualize o AOS para aplicar as animações de transição
        }, 5000);

        return () => clearInterval(interval);
    }, [activeIndex]);

    if(window.innerWidth < 600) {
        backgrounds[3] = "url('../img/heromobile_3.jpg')"
        backgrounds[1] = "url('../img/hero_2.jpg')"
    }

    return (
        <hero id="bgHero" style={{ backgroundImage: backgrounds[activeIndex] }}>
            <div id="titleBarber" className="title-barber" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img id="logoHero" src="img/logo_tipo.png" alt="Barbearia Deodoro" data-aos="fade-down-right" data-aos-duration="1500" />
                <h3>O lugar do Homem Moderno</h3>
            </div>

            <div id="titleMencare" className="title-barber" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <img id="logoHero" src="img/logo_mencare.png" alt="Barbearia Deodoro" data-aos="fade-down-right" data-aos-duration="1500" />
                <h3>Transformando autenticidade em Estilo</h3>
            </div>

            

            <div className="bullets">
                {backgrounds.map((background, index) => (
                    <div key={index} className={index === activeIndex ? "actived" : ""}></div>
                ))}
            </div>
        </hero>
    );
}
