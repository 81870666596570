import React, { useState, useEffect } from "react";
import './Footer.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Footer() {
    useEffect(() => {
        AOS.init();
    }, [])

    
  

    return(
        <footer>
            <div className="logoFooter">
                <div>
                    <img src="img/logo_marca.png" alt="Deodoro Barbearia" />
                    <img src="img/logo_marcabg.png" alt="Deodoro Barbearia" />
                </div>
                <h3 data-aos="zoom-in" data-aos-duration="1500" >R. Marechal Deodoro nº988</h3>
                <i data-aos="zoom-in" data-aos-duration="1500" >Centro - Santa Cruz do Sul - RS</i>
            </div>
            <div className="contact">

                <div className="phone" data-aos="fade-up-right" data-aos-duration="1500" >
                    <img src="../img/icon_phone.png" alt="Telefone"/>
                    <h3>+55 51 9 9844-4717</h3>
                </div>

                <div className="email" data-aos="fade-up-left" data-aos-duration="1500" >
                    <img src="../img/icon_email.png" alt="E-mail"/>
                    <h3>atendimento@barbeariadeodoro.com.br</h3>
                </div>
            </div>
            <hr/>
            <div className="copyright" data-aos="fade-up" data-aos-duration="1500" >
                <p>© <b>2023</b>. Todos os direitos reservados</p>
                
                <div className="social">
                    <a href=""><i className="fab fa-youtube" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
                    <a target="_blank" href="https://instagram.com/barbeariadeodoro"><i className="fab fa-instagram" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
                    <a target="_blank" href="https://wa.me/5551998444717?text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20um%20trato%20no%20visual."><i className="fab fa-whatsapp" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
                </div>
                <p>Desenvolvido por <a href="https://codeliny.com" target="_blank"><b>Code Liny</b></a></p>
            </div>
      
        </footer>
    )
}

export default Footer;
