import React, { Component } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
import './Depoiments.css';

export default class SlideDepoimentOne extends Component {
    componentDidMount() {
      AOS.init();
    }

    render() {
      const settings = {
        className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 1,
      autoplay: true,
      speed: 1500,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 940,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
      return (
        <div>
          <Slider {...settings}>
            
            <div className="card">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <a href="https://instagram.com/brunacruz_corretora"><img src="../img/depoiments_bruna.jpg" alt="" /></a>
                
                <p className="text">
                  <i class="fa-solid fa-quote-left"></i>
                    Amei o corte que fizeram no meu filho, sua primeira experiência com certeza foi marcante
                  <i class="fa-solid fa-quote-right"></i>
                </p>
                
                <h2>Bruna Cruz</h2>
                
              </div>
            </div>

            <div className="card">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <a href="https://instagram.com/vrdasilva"><img src="../img/depoiments_vinicius.jpg" alt="Vinicius Ribeiro da Silva" /></a>
                
                <p className="text">
                  <i class="fa-solid fa-quote-left"></i>
                    Serviço sencaional que vai muito além de um corte de cabelo ou barba. É uma experiência positiva para o Homem Moderno!
                  <i class="fa-solid fa-quote-right"></i>
                </p>
                
                <h2>Vinicius Ribeiro</h2>
                
              </div>
            </div>

            <div className="card">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <a href="https://instagram.com/guilhermeprochnow"><img src="../img/depoiments_guilherme.jpg" alt="Guilherme Prochnow" /></a>
                
                <p className="text">
                  <i class="fa-solid fa-quote-left"></i>
                     Atendimento nota 10, ambiente top, e os Kra sabem fazer uma barba e cortar o cabelo. Além disso as lâminas são sempre trocadas à cada cliente, e tem cerveja
                  <i class="fa-solid fa-quote-right"></i>
                </p>
                
                <h2>Guilherme Prochnow</h2>
                
              </div>
            </div>

            <div className="card">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <a href="https://instagram.com/julian_menezes"><img src="../img/depoiments_julian.jpg" alt="Julian Menezes" /></a>

                <p className="text">
                  <i class="fa-solid fa-quote-left"></i>
                    Profissionais excelentes, ótimo ambiente e serviço Nota Mil!
                  <i class="fa-solid fa-quote-right"></i>
                </p>
                
                <h2>Julian Menezes</h2>
                
              </div>
            </div>

            <div className="card">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <a href="https://www.instagram.com/fgrepres/"><img src="../img/depoiments_fernando.jpg" alt="Fernando Machado" /></a>

                <p className="text">
                  <i class="fa-solid fa-quote-left"></i>
                    Atendimento top, excelentes profissionais. Ambiente maravilhoso.
                  <i class="fa-solid fa-quote-right"></i>
                </p>
                
                <h2>Fernando Machado</h2>
                
              </div>
            </div>

            <div className="card">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <a href="https://www.instagram.com/guhfranke/"><img src="../img/depoiments_gustavo.jpg" alt="Gustavo Franke" /></a>

                <p className="text">
                  <i class="fa-solid fa-quote-left"></i>
                    Ótimo serviço. Pra quem busca qualidade em um corte de barba ou cabelo, fica aí a recomendação de barbearia, vocês vão gostar.
                  <i class="fa-solid fa-quote-right"></i>
                </p>
                
                <h2>Gustavo Franke</h2>
                
              </div>
            </div>

            <div className="card">
              <div data-aos="zoom-in" data-aos-duration="1500">
                <a href="https://www.instagram.com/alanton_becker/"><img src="../img/depoiments_alanton.jpg" alt="Alanton Becker" /></a>

                <p className="text">
                  <i class="fa-solid fa-quote-left"></i>
                    Ótimo lugar. Bem atenciosos com o cliente e preços justos. Recomendo!
                  <i class="fa-solid fa-quote-right"></i>
                </p>
                
                <h2>Alanton Becker</h2>
                
              </div>
            </div>
            
          </Slider>
        </div>
      );
    }
  }