import React, { Component } from "react";
import Slider from "react-slick";
import './SlideService.css'

export default class SlideService extends Component {
  constructor() {
    super();
    this.state = {
      selectedCard: null,
    };
  }

  openModal = (index) => {
    this.setState({ selectedCard: index });
  };

  closeModal = () => {
    this.setState({ selectedCard: null });
  };

  serviceClose = () => {
    this.setState({ selectedCard: null });
  };

  handleModalClick = (event) => {
    // Verifica se o clique ocorreu no fundo do modal (fora do conteúdo)
    if (event.target.classList.contains("modal")) {
      this.closeModal();
    }
  };

  render() {
    var titleService = '../img/title_services.png'
    var slideImg1 = '../img/service_barba.jpg'
    var slideImg2 = '../img/service_corte.jpg'
    var slideImg3 = '../img/service_barbacorte.jpg'
    var slideImg4 = '../img/service_kids.jpg'
    var slideImg5 = '../img/service_barboterapia.jpg'
    var slideImg6 = '../img/service_cortetesoura.jpg'
    var slideImg7 = '../img/service_barbatradicional.jpg'

    const cardsData = [
      {
        title: 'Barba',
        whats: 'https://wa.me/5551998444717?text=Ol%C3%A1%2C%20gostaria%20de%20me%20informar%20mais%20sobre%20o%20serviço%20de%20Barba%20Tradicional.',
        imageSrc: slideImg1,
        descriptionOne: `
        Barba Tradicional: A barba é alinhada conforme rosto e gosto do cliente. Aplicação de toalha quente e produtos Deodoro, permitindo assim um barbear na navalha muito mais suave.
        `,
        descriptionTwo: `
        Barba Express: Essa modalidade de barbear é feita somente com máquinas, proporcionando um barbear suave e rápido. Ideal para o homem que busca praticidade e precisa manter a barba alinhada semanalmente.
        `,
        obs: 'Barba Tradicional R$55',
        obs2: 'Barba Express R$45',
        priceOff: 'R$70,00',
        price: '',
        button: 'AGENDAR'
      },
      {
        title: 'Cortes',
        whats: 'https://wa.me/5551998444717?text=Ol%C3%A1%2C%20gostaria%20de%20me%20informar%20mais%20sobre%20o%20Corte.',
        imageSrc: slideImg2,
        descriptionOne: `
        Corte com Máquina: Feito somente com a máquina, seja no degradê ou raspado. Naquele estilo mais ousado.
        `,
        descriptionTwo: `Corte com Tesoura: Feito na tesoura (podendo ser usado a máquina também), com elegância e acabamento perfeito para seu visual.
        `,
        priceOff: 'R$70,00',
        price: '',
        obs: 'Corte c/ Máquina R$55',
        obs2: 'Corte c/ Tesoura R$60',
        obs3: 'Corte c/ Alexandre R$75',
        button: 'AGENDAR'
      },
      {
        id: '1',
        title: 'Dia do Noivo',
        whats: 'https://wa.me/5551998444717?text=Ol%C3%A1%2C%20gostaria%20de%20me%20informar%20mais%20sobre%20o%20Dia%20do%20Noivo.',
        imageSrc: slideImg3,
        descriptionOne: `
        Sem erro e sem frescura, aqui o homem também tem o seu dia! O Noivo e seus convidados poderão aproveitar um momento só deles, em um ambiente estritamente masculino.
        `,
        descriptionTwo: `
        Onde a barba é feita a moda antiga, os cortes seguem as últimas tendências. E além de você sair de visual novo, ainda poderá relaxar com as diversas bebidas que o bar oferece.
        `,
        priceOff:'',
        price: '',
        obs: 'Valores à consultar',
        button: 'AGENDAR'
      },
      {
        title: 'Corte Kids',
        whats: 'https://wa.me/5551998444717?text=Ol%C3%A1%2C%20gostaria%20de%20me%20informar%20mais%20sobre%20o%20Corte%20Kids.',
        imageSrc: slideImg4,
        descriptionOne: `
        A partir dos 3 meses até 12 anos. Pode ser na tesoura ou na máquina, como o pequeno grande homem preferir o seu visual.
        `,
        descriptionTwo: `
        1º corte: Além do corte, certificado com uma mechinha do cabelinho, fotos + video.
        `,
        priceOff: 'R$179,90',
        price: '',
        obs: 'Programa meu 1º Corte: R$100',
        obs2: 'Corte Kids: R$60',
        button: 'AGENDAR'
      },
      {
        title: 'Barboterapia',
        whats: 'https://wa.me/5551998444717?text=Ol%C3%A1%2C%20gostaria%20de%20me%20informar%20mais%20sobre%20a%20Barboterapia.',
        imageSrc: slideImg5,
        descriptionOne: `
        A barba é alinhada conforme rosto e gosto do cliente. É feito uma limpeza no rosto com esfoliação facial, que vai abrir os poros e permitir um barbear na navalha muito mais suave.
        `,
        descriptionTwo: `
        Além de uma massagem facial, aplicação de ozônio, toalhas quente e fria com essências para você relaxar completamente e curtir a experiência Deodoro, a barboterapia é finalizada com produtos (óleos, cremes, pós barba e bálsamo) da marca própria Deodoro Man Care. Deixando sua barba e rosto muito mais macio. Não deixe de solicitar este serviço na sua visita!
        `,
        obs: 'Barboterapia R$70',
        priceOff: 'R$120,00',
        price: '',
        button: 'AGENDAR'
      },
    ];

    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 3,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="slideService">
        <img src={titleService} alt="" />
        <Slider {...settings}>
          {cardsData.map((card, index) => (
            <div className="card" key={index} onClick={() => this.openModal(index)}>
              <div>
                <h2>{card.title}</h2>
                <h3 className="info" onClick={() => this.openModal(index)}>Saiba mais</h3>
              </div>
              <img src={card.imageSrc} alt="" />
            </div>
          ))}
        </Slider>

        {/* Modal */}
        {this.state.selectedCard !== null && (
          <div className="modal" onClick={this.handleModalClick}>
            <div className="modal-content">
              <img src={cardsData[this.state.selectedCard].imageSrc} alt="" />
              <div>
                <h2>{cardsData[this.state.selectedCard].title}</h2>
                
                <div className="scroll">
                    <div className="text">
                        <p>{cardsData[this.state.selectedCard].descriptionOne}</p>
                        <br/>
                        <p>{cardsData[this.state.selectedCard].descriptionTwo}</p>
                    </div>
                    <img src="../img/scroll.gif"></img>
                </div>
                <div className="price">
                    <h3>{cardsData[this.state.selectedCard].priceOff}</h3>
                    <h3>{cardsData[this.state.selectedCard].price}</h3>
                    <span>{cardsData[this.state.selectedCard].obs}</span>
                    <br/>
                    <span>{cardsData[this.state.selectedCard].obs2}</span>
                    <br/>
                    <span>{cardsData[this.state.selectedCard].obs3}</span>
                </div>
                  <a target="_blank" href={cardsData[this.state.selectedCard].whats}><button id="btnService">{cardsData[this.state.selectedCard].button}</button></a>
                  
                </div>
                <h2 onClick={this.serviceClose} id='shopClose'>X</h2>
            </div>
          </div>
        )}
      </div>
    );
  }
}
