import './Team.css'
import SlideService from './SlideService'
import SlideTeam from './SlideTeam';

function Team() {

  return (
    <section className="team" id="service">
      <SlideService/>
      <SlideTeam/>
    </section>
  );
};

export default Team;
