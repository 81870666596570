import React, { Component } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from "react-slick";
import './SlideTeam.css'



export default class SlideTeam extends Component {
  componentDidMount() {
    AOS.init();
  }

  render() {
  var titleTeam = '../img/title_team.png'
  var slideImg1 = '../img/team_1.jpg'
  var slideImg2 = '../img/team_2.jpg'
  var slideImg3 = '../img/team_3.jpg'
  var slideImg4 = '../img/team_4.jpg'
  var slideImg5 = '../img/team_5.jpg'
  var slideImg6 = '../img/team_6.jpg'
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "0px",
      slidesToShow: 5,
      autoplay: true,
      speed: 2000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="slideTeam">
        <img src={titleTeam} alt="" />
        <Slider {...settings}>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={slideImg1} alt="" />
            <div>
              <h3>CEO</h3>
              <h2>Alexandre</h2>
            </div>
            <a target="_blank" href="https://instagram.com/alexandreleindecker"><i className="fab fa-instagram" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={slideImg2} alt="" />
            <div>
              <h3>CEO</h3>
              <h2>Claudia</h2>
            </div>
            <a target="_blank" href="https://instagram.com/claudinhabuboltz"><i className="fab fa-instagram" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={slideImg3} alt="" />
            <div>
              <h3>Recepção</h3>
              <h2>Christopher</h2>
            </div>
            <a target="_blank" href="https://instagram.com/chrispaceman"><i className="fab fa-instagram" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={slideImg4} alt="" />
            <div>
              <h3>Barbeiro</h3>
              <h2>Rudi</h2>
            </div>
            <a target="_blank" href="https://instagram.com/tiorudi.kids"><i className="fab fa-instagram" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2000">
            <img src={slideImg5} alt="" />
            <div>
              <h3>Barbeiro</h3>
              <h2>Keven</h2>
            </div>
            <a target="_blank" href="https://instagram.com/keven_freitas"><i className="fab fa-instagram" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
          </div>
          <div className="card" data-aos="zoom-in" data-aos-duration="2500">
            <img src={slideImg6} alt="" />
            <div>
              <h3>Barbeiro</h3>
              <h2>Victor</h2>
            </div>
            <a target="_blank" href="https://instagram.com/victor_estraich"><i className="fab fa-instagram" data-aos="zoom-in" data-aos-duration="2500" ></i></a>
          </div>
        </Slider>
      </div>
    );
  }
}