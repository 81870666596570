import React, { useState } from 'react';
import emailjs, { send } from '@emailjs/browser';


function Popup() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')

    function closePopup() {
        var popModal = document.getElementById('popModal');
        if(popModal.style.display = "block"){
          popModal.style.display = "none";
        } else {
          popModal.style.display = "none"
        }
    }

    setTimeout(() => {
        var popModal = document.getElementById('popModal').style.display = "block"
    }, 5000);

    //formulario
    const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (selectedOption) {

      const whatsappLink = `https://picpay.me/deodoroclub`;
      window.open(whatsappLink, '_blank');
    }
  };

    return(
          <section id="popModal">
            <div className="container">
              <div>
                <img src="img/form_titleclub.png" alt="" />

                <form onSubmit={handleSubmit}>
                  <p>Escolha um plano semestral e desfrute da Experiência Deodoro por 6 meses!</p>

                  <label>
                    <img src="img/icon_club_cabelo.png" alt="" />
                    <h3>Corte Ilimitado</h3>
                    <input type="radio" name="servico" value="corte" checked={selectedOption === 'corte'} onChange={handleOptionChange} />
                  </label><br />

                  <label>
                    <img src="img/icon_club_bigode.png" alt="" />
                    <h3>Barba Ilimitada</h3>
                    <input type="radio" name="servico" value="barba" checked={selectedOption === 'barba'} onChange={handleOptionChange} />
                    
                  </label><br />

                  <label>
                    <img src="img/icon_club_cabelobarba.png" alt="" />
                    <h3>Corte + Barba Ilimitado</h3>
                    <input type="radio" name="servico" value="corte_e_barba" checked={selectedOption === 'corte_e_barba'} onChange={handleOptionChange} />
                    
                  </label><br />

                  <button type="submit">ASSINAR</button>
                </form>
              </div>

              <div>
                <h2 onClick={closePopup} id="closePopup">X</h2>
                <img src="img/form_comunity.jpg" alt="" />
              </div>
            </div>
          </section>
    )
}

export default Popup;