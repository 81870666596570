import React, { useState } from 'react';
import axios from 'axios';
import './Popup.css';

function Menu() {

    const [data, setData] = useState({
      nome: '',
      email: '',
      text: '',
    })

    function handleInputChange(e) {
      if (e.target.name === 'anexo')
        data[e.target.name] = e.target.files[0];
      else
        data[e.target.name] = e.target.value;
      setData(data);
    }

    function sendEmail(e) {
        e.preventDefault();
      
        const textSend = `Olá, meu nome é ${data.nome} e gostaria muito de fazer parte da Equipe Deodoro!\n\n
        ${data.text}\n\n
        Email: ${data.email}`;
        const whatsappLink = `https://wa.me/5551998444717?text=${encodeURIComponent(textSend)}`;
        
        window.open(whatsappLink, '_blank');
      }

    {/*
    function send(){
      const formData = new FormData();
      
      Object.keys(data).forEach(key => formData.append(key, data[key]));
      axios.post('/send', formData, {
        headers: {
            'Content-Type' : `multipart/form-data;boundary=${formData._boundary}`
        }
      })
      .then((response) => {
        alert(`Seus dados foram enviados, responderemos assim que possível!`);
        console.log(response);
      })
      .catch((error) => {
        alert("Houve algum erro ao enviar seu currículo");
        console.log(error);
      })
    }
    */}

    function openModalWork() {
        var workModal = document.getElementById("workModal");
        workModal.style.display = "block"

        var menuMobile = document.getElementById("menuMobile")
        if (menuMobile.style.display = "block") {
            menuMobile.style.display = "none"
        }

        document.getElementById("menuMobile").style.display = "none"
    };
    function closeModalWork() {
        var workModal = document.getElementById("workModal");

        if (workModal.style.display = "block") {
            workModal.style.display = "none"
        }
    }

    function refreshWeb() {
        window.scrollTo(0, 0);
    }

    function menuOpen() {
        document.getElementById("menuMobile").style.display = "block"
    }

    function closeMenu() {
        document.getElementById("menuMobile").style.display = "none"
    }
    return (
        <navbar className="menu_desktop">
            <div onClick={refreshWeb}>
                <img src="img/logo_marca.png" alt="Deodoro Barbearia" />
                <img src="img/logo_marcabg.png" alt="Deodoro Barbearia" />
            </div>

            <ul>
                <li><a href="#">INÍCIO</a></li>
                <li><a href="#about">SOBRE</a></li>
                <li><a href="#service">SERVIÇO</a></li>
                <li><a href="#mencare">MEN CARE</a></li>
            </ul>

            <div id="menuMobile">
                <div>
                    <a onClick={closeMenu} href="#">INÍCIO</a>
                    <a onClick={closeMenu} href="#about">SOBRE</a>
                    <a onClick={closeMenu} href="#service">SERVIÇO</a>
                    <a onClick={closeMenu} href="#mencare">PRODUTOS MEN CARE</a>

                    <a onClick={openModalWork}>TRABALHE CONOSCO</a>
                    <h2 onClick={closeMenu} id="closeMenu">X</h2>
                </div>
            </div>

            <a onClick={openModalWork}><button>TRABALHE CONOSCO</button></a>
            <button onClick={menuOpen} id='menuButton'>MENU</button>

            <section id="workModal">
                <div className="container">
                    <div>
                        <img src="img/form_title.png" alt="" />

                        <form onSubmit={sendEmail}>
                            <p>Envie seu currículo e junte-se à nós!</p>
                            <label>Nome</label>
                            <input type="text" id="nome" name="nome" placeholder="João Carlos" onChange={handleInputChange} required />
                            <label>E-mail</label>
                            <input type="email" id="email" name="email" placeholder="joaocarlos@gmail.com" onChange={handleInputChange} required />

                            <label>Experiência</label>
                            <input type="text" id="text" name="text" placeholder="Descreva sua experiência..." onChange={handleInputChange} required />
                            
                            <button type="submit">ENVIAR</button>
                        </form>
                    </div>

                    <div>
                        <h2 onClick={closeModalWork} id="modalClose">X</h2>
                        <img src="img/form_job.jpg" alt="" />
                    </div>
                </div>
            </section>
        </navbar>
    )
};

export default Menu;
