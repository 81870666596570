import React, { useEffect, useState } from "react";
import moment from "moment";
import "./PromotionTimer.css"; // Importe o arquivo CSS aqui
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

const PromotionTimer = () => {
  useEffect(() => {
    AOS.init();
  }, [])

  // Data de término da promoção (ajuste conforme necessário)
  const promotionEndDate = moment("2023-12-31 23:59:59");

  // Estado para armazenar o tempo restante
  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining());

  // Função para calcular o tempo restante
  function getTimeRemaining() {
    const now = moment();
    const diff = promotionEndDate.diff(now);
    const duration = moment.duration(diff);

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeRemaining());
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  function closePromotion(){
    var whatsApp = document.getElementById("whatsButton")
    var footer = document.getElementById("promotion");

    footer.style.display = "none"

    if (window.innerWidth > 700) {
      whatsApp.style.bottom = "15%"
      
    } else {
      whatsApp.style.bottom = "10%"
      
    }
  }
  window.addEventListener('scroll', function() {
    
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 10) {
      var btn = document.getElementById("whatsButton").style.opacity = "0"
    } else { 
      var btn = document.getElementById("whatsButton").style.opacity = "1"
     }
});

  return (
    <>
      <div id="whatsButton" className='whatsButton' data-aos="fade-up">
        <a href='https://wa.me/5551998444717?text=Ol%C3%A1%2C%20gostaria%20de%20agendar%20um%20hor%C3%A1rio.'><img src="img/icon_whatsbutton.png"></img></a>
      </div>

       {/*
      <div  id="promotion" className="promotion-timer" data-aos="fade-up">
        <h2 onClick={closePromotion} id="promotionClose">X</h2>
        {timeRemaining.days > 0 && (
          <span>{`${timeRemaining.days}`} <b>dias</b></span>
        )}
        <h2>:</h2>
        <span>{`${timeRemaining.hours.toString().padStart(2, "0")}`} <b>horas</b></span>
        <h2>:</h2>
        <span>{`${timeRemaining.minutes.toString().padStart(2, "0")}`} <b>min</b></span>
        <h2>:</h2>
        <span>{`${timeRemaining.seconds.toString().padStart(2, "0")}`} <b>seg</b></span>
      </div>
      */}
    </>
  );
};

export default PromotionTimer;
