import Popup from './Popup';
import About from './About';
import Team from './Team';
import Depoiments from './Depoiments';
import BarberShop from './BarberShop';

function Main() {
    return(
        <main>
            <Popup/>
            <About/>
            <Team/>
            <Depoiments/>
            <BarberShop/>
        </main>
    )
};

export default Main;